@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

$--second-font: 'Inter', sans-serif;
$--primary-color: #14133C;
$--secondary-color:  #00EAFF;
$--font-color-secundary:  #00EAFF;
$--third--color: #1E0E62;
$--color-black : black;
$--color-write : white;
$--sm: 14px;
$--md:16px;
$--lg:18px;

