@import '../../vars';

.title_recovery{
    font-size: $--md;
    font-family: $--second-font;
    color: $--primary-color;
    margin: 49px 0px 0px 0px;
    letter-spacing: -0.4px;
}
.form_container{
    display: grid;
    grid-template-rows: auto 1fr auto;
    width: 300px;
    font-weight: 500;
    line-height: 19px;
}