@import '../../../vars';

.item-container{
    list-style:none;
    padding-bottom: 30px;
}
.item-container-option{
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px 6px 22px 3px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 21px 24px 21px 38px;
    display: flex;
    align-items: center;
}

.item-access{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.item-access-onclick{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.item-name{
    font-family: $--second-font;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 2px;
}

.item-option-icons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}
.item-option-icon{
    margin-right: 10px;
    transform: rotate(180deg);
}
.item-option-icon-invert{
    margin-right: 10px;
}
.item-access-info{
    box-shadow: 0px 6px 22px 3px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 34px 0px 46px 38px;
    font-family: $--second-font;
    letter-spacing: 2px;
    & p{
        color: #858585;
        margin: 5px 0px 14px 0px;
    }
}
.item-access-info-title{
    font-weight: 600;
    font-size: 10px;
    color: #045DFF;
}

// responsive hasta 950
@media (max-width: 950px) and (min-width: 541px){
}
// responsive hasta 540
@media (max-width: 540px) {
    .item-option-icons{
        display: none;
    }
    .item-name{
        font-size: 11px;
        letter-spacing: 2px;
    }
}