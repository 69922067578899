@import "../../vars";

.title {
  font-weight: 700;
  font-size: 50px;
  line-height: 52px;
  letter-spacing: -0.4px;
  padding-bottom: 30px;
}
.subtitle {
  font-weight: $--md;
  font-weight: 400;
  padding: 0.5rem;
  text-align: start;
  font-size: 24px;
  line-height: 31px;
}
.background1 {
  z-index: -2;
  min-width: 800px;

  /* Escalamiento proporcional */
  width: 100%;
  height: 100%;

  /* Posicionamiento */
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}
.background2 {
  z-index: -2;
  min-width: 800px;
  opacity: 0.5;

  /* Escalamiento proporcional */
  width: 100%;
  height: 100%;

  /* Posicionamiento */
  position: fixed;
  top: 0;
  left: 0;
}
.background3 {
  min-width: 800px;

  /* Escalamiento proporcional */
  width: 100%;
  height: 100%;

  /* Posicionamiento */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(
    180deg,
    #14133c 0%,
    rgba(20, 19, 60, 0.25) 115.18%
  );
  mix-blend-mode: multiply;
}

.container_auth {
  z-index: 1;
  margin: 50px 50px 50px 50px;
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 50px;
  padding-left: 5rem;
  height: 100%;
  margin-bottom: 5rem;
}
.text_left {
  color: $--color-write;
  margin: 100px 100px 0px 100px;
}

.container_auth_form {
  width: 70%;
  display: flex;
  flex-direction: column;
  place-items: center;
  background-color: $--color-write;
  border-radius: 10px;
  padding-bottom: 60px;
}
.nav_auth {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 19px;
  & button {
    border-radius: 8px;
    border: none;
    color: rgba(21, 20, 57, 0.4);
    cursor: pointer;
    font-size: $--sm;
    height: 68px;
    width: 100%;
    background-color: $--color-write;
    line-height: 26px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}
.button_active {
  border-bottom: 3px solid $--third--color;
  width: 100%;
  & button {
    color: $--third--color;
  }
}
.button_disable {
  width: 100%;
  border-bottom: 3px solid #ebeaed;
}
.form_container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 0px 71px 0px 71px;
  & input {
    font-family: $--second-font;
    mix-blend-mode: normal;
    width: 100%;
    border: 2px solid #ebeaed;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 6px;
    height: 50px;
    margin-top: 30px;
    padding: 12px 0px 12px 24px;
  }
}
.label_form {
  font-family: $--second-font;
}
.btn {
  border-radius: 100px;
  border: 0;
  color: $--color-write;
  height: 50px;
  font-size: $--lg;
  cursor: pointer;
  font-family: $--second-font;
}
.btn_primary {
  background-color: $--primary-color;
  margin-bottom: 19px;
  margin-top: 67px;
  color: $--secondary-color;
}
.btn_secondary {
  background-color: $--secondary-color;
  margin-top: 19px;
}

.footer-class {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: auto;
  justify-content: center;
}

.footer-divider {
  margin: auto;
  width: 100%;
  height: 1px;
  background: #ebeaec;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 5px 5px 0px;
}

.content-footer {
  margin: auto;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding-left: 4rem;
  padding-right: 4rem;
  max-width: 1440px;
}

.display-flex {
  display: flex;
  flex-direction: row;
}

.icon-location {
  margin-top: 1rem;
  margin-right: 1rem;
}

.box {
  color: #ffffff;
  width: 50%;
}

.footer-items-corporate {
  display: flex;
  justify-content: center;
}

.item-left {
  text-align: end;
}

.text-bottom-footer {
  margin-top: -1rem;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
}

// responsive hasta 950
@media (max-width: 950px) and (min-width: 541px) {
  .container_auth_form {
    width: 80%;
    display: flex;
    flex-direction: column;
    place-items: center;
    background-color: $--color-write;
    border-radius: 10px;
  }
  .container_auth {
    z-index: 1;
    margin: 50px 0px 50px 0px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    height: 100%;
  }
  .text_left {
    margin: 0px 100px 0px 100px;
  }
}
@media (max-height: 853px) and (min-width: 1000px) {
  .container_auth {
    margin: 0px 0px 100px 0px;
  }
}

@media (min-width: 1001px) and (max-width: 1200px){
  .text_left {
    margin: 0px 50px 0px 0px;
  }
}

// @media (min-width: 1599px) {
//   .content-footer {
//     padding-left: 50rem;
//     padding-right: 50rem;
//   }
//   .footer-divider {
//     width: 1650px;
//   }
// }

@media (max-height: 678px) and (min-width: 1000px) {
  .nav_auth {
    margin-bottom: 0px;
  }
}
@media (max-height: 658px) and (min-width: 1000px) {
  .form {
    padding: 0px 40px 0px 40px;
    & input {
      margin-top: 10px;
    }
  }
  .btn_primary {
    background-color: $--primary-color;
    margin-bottom: 0px;
    margin-top: 30px;
  }
  .btn_secondary {
    background-color: $--secondary-color;
    margin-top: 0px;
  }
  .text_left {
    padding: 20px;
  }
}
// responsive hasta 540
@media (max-width: 540px) {
  .container_auth {
    z-index: 1;
    margin: 50px 0px 100px 0px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding-left: 0rem;
  }
  .container_auth_form {
    width: 96%;
    display: flex;
    flex-direction: column;
    place-items: center;
    background-color: $--color-write;
    border-radius: 10px;
  }
  .form {
    padding: 0px 40px 0px 40px;
  }
  .text_left {
    margin: 0px 10px 0px 10px;
  }
  .display-flex{
    justify-content: center;
  }
  .box {
    color: #ffffff;
    width: 100%;
  }
}
// //responsive hasta 320
@media (max-width: 320px) {
  .container_auth_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    place-items: center;
    background-color: $--color-write;
    border-radius: 10px;
  }
}
