@import '../../vars';

.span{
    margin: 9px 0px 19px 0px;
    color: rgba(21, 20, 57, 0.4);
    font-size: $--md;
    text-align: center;
    
}

.recovery_password{
    font-family: $--second-font;
    margin: 3px 0px 0px 0px;
    font-size: $--md;
    color: #6A677B;
    text-decoration: underline;
    cursor: pointer;
    padding-left: 10px;
}