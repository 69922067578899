@import '../../vars';


.footer-container{
    padding: 0px 100px 0px 100px;
    margin-top: auto;
    // position:absolute;
    // bottom: 0;
    // width: 100%;
}

.footer-items{
    display: grid;
    grid-template-columns: 20% 60%;
    // display: flex;
    // justify-content: space-around;
    // align-items: center;
}

.footer-container-logo{
    text-align: right;
}
.footer-container-copyright{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 20px;
    color: #858585;
    opacity: 0.65;
}

.line-footer{
    text-align: center;
    position: relative;
    z-index: 1;
    // margin: 0px;
    // padding: 0px;
    &::before{
        border-top: 2px solid  #EBEAEC;;
        // opacity: 0.1;
        content: "";
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: -1;
    }
    & span{
        letter-spacing: -0.2px;
        color: #141414;
        mix-blend-mode: normal;
        opacity: 0.5;
        padding: 0 15px;
        background-color: $--color-write;
    }
}
// responsive hasta 540
@media (max-width: 540px) {
    .footer-container{
        padding: 0px 50px 0px 50px;
    }
    .footer-items{
        grid-template-columns: 50% 50%;
    }
    .footer-container-logo{
        text-align: -webkit-auto;
    }
}