@import '../../vars';

.image_logo{
    width: 150px;
    height: 130px;
}

.header_auth{
    padding: 0px 0px 0px 39px;
}
// responsive hasta 540
@media (max-width: 540px) {
    .header_auth{
        padding: 0px;
    }
}