@import '../../vars';

.popup-container{
    background: rgba(32, 35, 41, 0.61);
    position: fixed;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:3;
  
   
}
.popup-item{
    background: white;
    border-radius: 10px;
    padding: 64px 85px 50px 85px;
    margin: 390px;
}
.icon-popup-status{
    text-align: center;
    align-items: center;
    margin-bottom: 55px;
}
.title-popup{
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.4px;
    margin-bottom: 11px;
}
.text-popup{
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.4px;
}
.popup-container-options{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    margin-top: 50px;
}

.popup-option-btn{
    cursor: pointer;
    color: $--color-write;
    border: none;
    font-weight: 500;
    border-radius: 22px;
    width: 135px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup-option-accept{
    background: none repeat scroll 0 0 #3CC139;
    &:hover{
        background: none repeat scroll 0 0 #60b85e;
    }
}
.popup-option-deny{
    background: none repeat scroll 0 0 #F25A5A;
    &:hover{
        background: none repeat scroll 0 0 #ee6969;
    }
}