@import '../../vars';

.span{
    margin: 9px 0px 19px 0px;
    color: rgba(21, 20, 57, 0.4);
    font-size: $--md;
    text-align: center;
    
}
.container-btn{
    width: 100%;
}